import React, { useState, useEffect } from "react"
import { CardElement, useStripe, useElements } from "@stripe/react-stripe-js"
import Spinner from "react-spinner-material"

export default function CheckoutForm({
  succeeded,
  setSucceeded,
  setStripePaymentIntentId,
}) {
  const [error, setError] = useState(null)
  const [processing, setProcessing] = useState("")
  const [disabled, setDisabled] = useState(true)
  const [clientSecret, setClientSecret] = useState("")
  const stripe = useStripe()
  const elements = useElements()
  useEffect(() => {
    // Create PaymentIntent as soon as the page loads
    window
      .fetch("/.netlify/functions/create-payment-intent", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
      })
      .then(res => {
        return res.json()
      })
      .then(data => {
        console.log("data", data)

        setClientSecret(data.secret)
      })
  }, [])
  const cardStyle = {
    style: {
      base: {
        color: "#32325d",
        fontFamily: "Arial, sans-serif",
        fontSmoothing: "antialiased",
        fontSize: "16px",
        "::placeholder": {
          color: "#32325d",
        },
      },
      invalid: {
        color: "#fa755a",
        iconColor: "#fa755a",
      },
    },
  }
  const handleChange = async event => {
    // Listen for changes in the CardElement
    // and display any errors as the customer types their card details
    setDisabled(event.empty)
    setError(event.error ? event.error.message : "")
  }
  const handleSubmit = async ev => {
    ev.preventDefault()
    setProcessing(true)
    const payload = await stripe.confirmCardPayment(clientSecret, {
      payment_method: {
        card: elements.getElement(CardElement),
      },
    })
    if (payload.error) {
      setError(`Payment failed ${payload.error.message}`)
      setProcessing(false)
    } else {
      setError(null)
      setProcessing(false)
      setStripePaymentIntentId(payload.paymentIntent.id)
      setSucceeded(true)
    }
  }
  return (
    <form id="payment-form" onSubmit={handleSubmit}>
      <CardElement
        id="card-element"
        options={cardStyle}
        onChange={handleChange}
      />
      <br />
      {/* <button
        disabled={processing || disabled || succeeded}
        id="submit"
        className="button fs--s consultancy-button primary"
      >
        <span id="button-text">
          {processing ? (
            <div className="spinner" id="spinner"></div>
          ) : (
            "Betal og indsend dato"
          )}
        </span>
      </button> */}

      <button className="button fs--s consultancy-button primary">
        <div className="button-text">Betal og indsend dato</div>
        <div className="button-icon">
          {processing && (
            <Spinner radius={13} color={"#fff"} stroke={1} visible={true} />
          )}
          {!processing && (
            <svg className="stroke" viewBox="0 0 24 24">
              <path d="M9 18l6-6-6-6"></path>
            </svg>
          )}
        </div>
      </button>

      {error && (
        <div className="card-error" role="alert">
          {error}
        </div>
      )}
    </form>
  )
}
