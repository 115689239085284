import React, { useContext, useState, useEffect } from "react"
import Spinner from "react-spinner-material"
import StoreContext from "../context/StoreContext"
import DatePicker, { registerLocale } from "react-datepicker"
import "react-datepicker/dist/react-datepicker.css"
import useProject from "../utils/useProject"
import { getDay, addDays } from "date-fns"
import { ToastContainer, toast } from "react-toastify"
import "react-toastify/dist/ReactToastify.css"
import { loadStripe } from "@stripe/stripe-js"
import { Elements } from "@stripe/react-stripe-js"
import CheckoutForm from "../components/CheckoutForm"

import enGb from "date-fns/locale/en-GB"
registerLocale("en-gb", enGb)

const promise = loadStripe(
  "pk_live_51IX2StLYhgYBhAl5M0hwOe6JMjT3SC1cMuAHw9nVUIFEg4m7LsXJoCv58XNaq8qOv00Gp5Wx5XAUgUjQcQppqTI700vqN9kDBC"
)

const Calendar = ({ data }) => {
  const context = useContext(StoreContext)
  const [date, setDate] = useState()
  const [firstName, setFirstName] = useState()
  const [lastName, setLastName] = useState()
  const [telephone, setTelephone] = useState()
  const [streetName, setStreetName] = useState()
  const [zipcode, setZipcode] = useState()
  const [city, setCity] = useState()
  const [phoneNumber, setPhoneNumber] = useState()
  const [isLoading, setLoading] = useState(false)
  const [isLoaded, setLoaded] = useState(false)
  const [startDate, setStartDate] = useState(new Date())
  const [availableDates, setAvailableDates] = useState([])
  const excludedDates = []
  const [succeeded, setSucceeded] = useState(false)
  const [stripePaymentIntentId, setStripePaymentIntentId] = useState()

  // const [project, setProject] = useState()

  let dateType
  let projectId

  if (typeof document !== "undefined") {
    let params = new URLSearchParams(document.location.search.substring(1))
    dateType = params.get("type") ? params.get("type") : "mover"
    projectId = params.get("id") ? params.get("id") : 1234
  }

  const project = useProject(projectId)

  const fetchDates = async () => {
    const response = await fetch(
      "/.netlify/functions/fetch-dates?type=" + dateType,
      {
        headers: {
          "Content-Type": "application/json",
        },
      }
    )

    let json = await response.json()

    const datesArray = []
    for (let date of json.dates) {
      let projectsBookedOnDay = json.bookedDates[
        date.fields["Measurement date"]
      ]
        ? json.bookedDates[date.fields["Measurement date"]]
        : 0

      if (projectsBookedOnDay < 5) {
        datesArray.push(new Date(date.fields["Measurement date"]))
      }
    }

    setAvailableDates(datesArray)
  }

  useEffect(() => {
    fetchDates()
    console.log("fetch booked dates")
  }, [])

  useEffect(() => {
    console.log("stripePaymentIntentId !! ", stripePaymentIntentId)
  }, [stripePaymentIntentId])

  useEffect(() => {
    if (!succeeded) {
      return
    }
    handleSubmit()
  }, [succeeded])

  if (!project) {
    return "Loading"
  }

  const railShippingDate = project.project.fields["Aluproff shipping"]
  const measurementFree = !!project.project.fields["Measurement free"]

  let shippingDate
  if (railShippingDate) {
    let date = new Date(railShippingDate)
    shippingDate = date.getDate() + "/" + date.getMonth() + 1
  }

  //not used right now

  // const isWeekday = date => {
  //   const day = getDay(date)
  //   if (dateType === "measurement") {
  //     return day === 2
  //   } else {
  //     return day === 3
  //   }
  //   // return day === 3 || day === 5
  // }

  // //exclude every other week
  // for (let i = 0; i < 100; i++) {
  //   excludedDates.push(addDays(new Date("2020-12-16"), 14 * i))
  // }

  const handleSubmit = async event => {
    if (event) {
      event.preventDefault()
    }

    setLoading(true)

    let customerId = project.project.fields.Customer
      ? project.project.fields.Customer[0]
      : ""

    if (dateType === "measurement") {
      const customerData = {
        firstName,
        lastName,
        email: project.project.fields.Email,
        phoneNumber,
        streetName,
        zipcode,
        city,
        country: "Danmark",
        projects: [projectId],
        stripePaymentIntentId,
      }

      await fetch(`/.netlify/functions/update-customer?id=${customerId}`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(customerData), // body data type must match "Content-Type" header
      })
    }

    const data = {
      date: startDate,
      dateType,
    }

    const response = await fetch(
      "/.netlify/functions/update-project?id=" + projectId,
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(data), // body data type must match "Content-Type" header
      }
    )
    let a = await response.json()
    setLoading(false)

    if (a.success) {
      setLoaded(true)
      toast.success("Dato er blevet gemt", {
        position: "bottom-right",
        autoClose: 5000,
        hideProgressBar: true,
        closeOnClick: true,
      })
    }
  }

  const text = {
    measurement: {
      title: "Book til opmåling",
      description: `Vælg en dato herunder for opmåling. Vi kommer ud mellem kl. 7-15. Montøren kontakter dig 30 minutter inden ankomst.
      <br/><br/>
      Forud for besøget vil vi bede dig flytte alle genstande fra vindueskarmene, samt sørge for at gulvet er ryddet 1 meter fra væggen/loftet, hvor skinnen skal monteres.
      <br/><br/>
      Hvis du ønsker at aflyse eller flytte din tid med montøren, skal dette gøres senest 48 timer før planlagt besøg per mail hi@anddrape.com. Ved manglende aflysning vil det koste et start gebyr på DKK 1.450,-`,
    },
    installation: {
      title: "Book til montering",
      description: `Vælg en dato herunder for montering. Vi kommer ud mellem kl. 7-15. Montøren kontakter dig 30 minutter inden ankomst.
      <br/><br/>
      Forud for besøget vil vi bede dig flytte alle genstande fra vindueskarmene, samt sørge for at gulvet er ryddet 1 meter fra væggen/loftet, hvor skinnen skal monteres.
      <br/><br/>
      Hvis du ønsker at aflyse eller flytte din tid med montøren, skal dette gøres senest 48 timer før planlagt besøg per mail hi@anddrape.com. Ved manglende aflysning vil det koste et start gebyr på DKK 1.450,-`,
    },
    mover: {
      title: "Book til levering",
      description: `Så er dine gardiner ved at blive syet og din gardinskinne ved at blive fremstillet. Dine skinner er klar til levering fra den ${shippingDate}. Vælg en dato herunder hvor du ønsker at få leveret skinnen. Vi leverer mellem 15 og 18. Er du ikke hjemme, kan vi sagtens lægge skinnen i en carport eller lignende.`,
    },
  }

  let requirePayment = dateType === "measurement" && !measurementFree
  console.log("🚀 ~ requirePayment", requirePayment)

  return (
    <>
      <div
        className="measuring-guide-container project"
        style={{ paddingBottom: "80px" }}
      >
        <ToastContainer />

        <h1 className="fs--xl lh--s">
          {text[dateType] && text[dateType].title}
        </h1>
        <div
          dangerouslySetInnerHTML={{ __html: text[dateType].description }}
        ></div>

        <form onSubmit={handleSubmit}>
          <div className="calendar-form-info">
            <div className="react-datepicker-wrapper">
              <DatePicker
                wrapperClassName="datePicker"
                className="myDatePicker"
                selected={startDate}
                onChange={date => setStartDate(date)}
                minDate={
                  dateType === "mover" ? new Date(railShippingDate) : new Date()
                }
                // filterDate={isWeekday}
                // excludeDates={excludedDates}
                includeDates={availableDates}
                locale="en-gb"
                inline
              />
            </div>
          </div>

          {dateType === "measurement" && (
            <div className="calendar-form-info">
              <div>
                <div style={{ float: "left", width: "47%", marginRight: "6%" }}>
                  <input
                    type="text"
                    name="firstname"
                    onChange={e => {
                      setFirstName(e.target.value)
                    }}
                    placeholder="Fornavn"
                    className="input"
                  />
                </div>
                <div style={{ float: "left", width: "47%" }}>
                  <input
                    type="text"
                    name="lastname"
                    onChange={e => {
                      setLastName(e.target.value)
                    }}
                    placeholder="Efternavn"
                    className="input"
                  />
                </div>
              </div>

              <input
                type="text"
                name="streetname"
                onChange={e => {
                  setStreetName(e.target.value)
                }}
                placeholder="Gadenavn og nummer"
                className="input"
              />

              <div>
                <div style={{ float: "left", width: "47%", marginRight: "6%" }}>
                  <input
                    type="text"
                    name="zipcode"
                    onChange={e => {
                      setZipcode(e.target.value)
                    }}
                    placeholder="Postnummer"
                    className="input"
                  />
                </div>
                <div style={{ float: "left", width: "47%" }}>
                  <input
                    type="text"
                    name="city"
                    onChange={e => {
                      setCity(e.target.value)
                    }}
                    placeholder="By"
                    className="input"
                  />
                </div>
              </div>
              <input
                type="text"
                name="phoneNumber"
                onChange={e => {
                  setPhoneNumber(e.target.value)
                }}
                placeholder="Telefonnummer"
                className="input"
              />
              <br />
              <br />
            </div>
          )}

          {!requirePayment && (
            <button className="button fs--s consultancy-button primary">
              <div className="button-text">Indsend dato</div>
              <div className="button-icon">
                {isLoading && (
                  <Spinner
                    radius={13}
                    color={"#fff"}
                    stroke={1}
                    visible={true}
                  />
                )}
                {!isLoading && (
                  <svg className="stroke" viewBox="0 0 24 24">
                    <path d="M9 18l6-6-6-6"></path>
                  </svg>
                )}
              </div>
            </button>
          )}
        </form>

        {requirePayment && (
          <div className="calendar-form-info">
            <div style={{ marginBottom: 20 }}>
              Det koster 3.050 kr. at få opmåling. Indtast dine
              kortinformationer herunder og book din måling.
            </div>
            <Elements stripe={promise}>
              <CheckoutForm
                succeeded={succeeded}
                setSucceeded={setSucceeded}
                setStripePaymentIntentId={setStripePaymentIntentId}
              />
            </Elements>
          </div>
        )}
        {isLoaded && !requirePayment && (
          <div style={{ color: "green" }}>Datoen er blevet gemt </div>
        )}
        {isLoaded && requirePayment && (
          <div style={{ color: "green" }}>
            Betalingen er gennemført og datoen er blevet gemt.
          </div>
        )}
      </div>
    </>
  )
}

export default Calendar
